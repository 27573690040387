import { Injectable } from '@angular/core';
import { and, where } from '@angular/fire/firestore';
import { Club } from '@models/clubs';
import { COLLECTIONS } from '@models/collections';
import { Movement } from '@models/inscription';
import { LicenseType, LicenseUserRate } from '@models/license';
import { PaymentType } from '@models/masters';
import { Match } from '@models/match';
import { Team } from '@models/teams';
import { User, UserGender } from '@models/user';
import { combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { SeasonManagerService } from './season-manager.service';
import { SeasonsService } from './seasons.service';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private readonly expenses$ = this.firestore
    .listDocuments<Movement>(COLLECTIONS.MOVEMENTS, where('movementType', '==', 'EXPENDITURE'))
    .pipe(shareReplay(1));

  private readonly incomes$ = this.firestore
    .listDocuments<Movement>(COLLECTIONS.MOVEMENTS, where('movementType', '==', 'INCOME'))
    .pipe(shareReplay(1));

  private readonly teams$ = this.seasonManager.season$.pipe(
    switchMap((season) => this.firestore.listDocuments<Team>(COLLECTIONS.TEAMS, where('seasonYear', '==', season))),
    shareReplay(1),
  );

  private readonly clubs$ = this.firestore.listDocuments<Club>(COLLECTIONS.CLUBS).pipe(shareReplay(1));

  private readonly licenses$ = this.seasonManager.season$.pipe(
    switchMap((seasonYear) =>
      this.firestore.listDocumentsGroup<LicenseUserRate>(
        COLLECTIONS.USERS_LICENSES,
        where('seasonYear', '==', seasonYear),
      ),
    ),
    shareReplay(1),
  );
  private readonly users$ = this.firestore.listDocuments<User>(COLLECTIONS.USERS).pipe(shareReplay(1));

  private readonly matches$ = this.seasonManager.season$.pipe(
    switchMap((seasonYear) =>
      this.firestore.listDocuments<Match>(
        COLLECTIONS.MATCH,
        where('seasonYear', '==', seasonYear),
        where('status', '==', 'FINISHED'),
      ),
    ),
    shareReplay(1),
  );

  public adminStats$ = combineLatest([this.expenses$, this.incomes$, this.teams$, this.licenses$, this.clubs$]).pipe(
    map(([expenses, incomes, teams, licenses, clubs]) => {
      const totalExpenses = expenses.reduce((acc, curr) => acc + +curr.amount, 0);
      const totalIncomes = incomes.reduce((acc, curr) => acc + +curr.amount, 0);
      const incomesByType = incomes.reduce(
        (acc, income) => {
          const type = income.paymentType as PaymentType;
          if (type && typeof type === 'string') {
            if (!acc[type]) {
              acc[type] = 0;
            }
            acc[type] += +income.amount;
          }
          return acc;
        },
        {} as Record<PaymentType, number>,
      );

      const licensesByUserAndType = licenses.reduce((acc, license) => {
        const { userId, licenseType } = license;

        if (typeof userId !== 'string' || typeof licenseType !== 'string') {
          return acc;
        }

        if (!acc.has(userId)) {
          acc.set(userId, new Set<string>());
        }

        return acc;
      }, new Map<string, Set<string>>());

      const licensesByType = Array.from(licensesByUserAndType.values()).reduce(
        (acc, licenseTypesSet) => {
          licenseTypesSet.forEach((type) => {
            if (!acc[type]) {
              acc[type] = 0;
            }
            acc[type]++;
          });
          return acc;
        },
        {} as Record<string, number>,
      );

      const incomesLicensesByType = licenses.reduce(
        (acc, license) => {
          const licenseType = license.licenseType as LicenseType;
          const amount = license.price;

          if (!licenseType || typeof licenseType !== 'string' || !amount) {
            return acc;
          }

          if (!acc[licenseType]) {
            acc[licenseType] = 0;
          }
          acc[licenseType] += +amount;

          return acc;
        },
        {} as Record<LicenseType, number>,
      );

      const incomesLicensesByGender = licenses.reduce(
        (acc, license) => {
          const gender = license.gender as UserGender;
          const amount = license.price;

          if (!gender || typeof gender !== 'string' || !amount) {
            return acc;
          }

          if (!acc[gender]) {
            acc[gender] = 0;
          }
          acc[gender] += +amount;

          return acc;
        },
        {} as Record<UserGender, number>,
      );

      const expensesByClub = expenses.reduce(
        (acc, expense) => {
          const clubId = expense.clubId;
          const amount = expense.amount;

          if (!clubId || typeof clubId !== 'string' || !amount) {
            return acc;
          }

          const club = clubs.find((c) => c.id === clubId);
          const clubName = club ? club.name : 'Unknown Club';

          if (!acc[clubName]) {
            acc[clubName] = 0;
          }
          acc[clubName] += +amount;

          return acc;
        },
        {} as Record<string, number>,
      );

      const sortedExpensesByClub = Object.entries(expensesByClub)
        .sort(([, a], [, b]) => b - a)
        .reduce(
          (acc, [key, value]) => {
            acc[key] = value;
            return acc;
          },
          {} as Record<string, number>,
        );

      const uniqueUsersWithLicenses = licensesByUserAndType.size;

      return {
        totalExpenses,
        totalIncomes,
        netBalance: totalExpenses - totalIncomes,
        teamsCount: teams.length,
        uniqueUsersWithLicenses,
        licensesByType,
        incomesByType,
        incomesLicensesByType,
        incomesLicensesByGender,
        expensesByClub: sortedExpensesByClub,
      };
    }),
  );

  public allMatches$ = this.seasonsService.all$.pipe(
    switchMap((seasons) => {
      const seasonIds = seasons.map((season) => season.id);
      const chunkArray = (array: string[], size: number) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, size + i));
        }
        return result;
      };

      const seasonsChunks = chunkArray(seasonIds, 30);
      return combineLatest(
        seasonsChunks.map((seasonChunk) =>
          this.firestore
            .listDocuments<Match>(COLLECTIONS.MATCH, and(where('seasonId', 'in', seasonChunk)))
            .pipe(map((matches) => matches)),
        ),
      ).pipe(map((results) => results.flat()));
    }),
    shareReplay(1),
  );

  public matchesSummary$ = combineLatest([this.allMatches$, this.seasonsService.all$, this.teams$]).pipe(
    map(([matches, seasons, teams]) => {
      const ongoingMatches = matches.filter((match) => match.status === 'IN-PROGRESS').length;

      const finishedMatches = matches.filter((match) => match.status === 'FINISHED').length;
      const pendingMatches = matches.filter((match) => match.status === 'PENDING').length;

      const matchesWithIncidents = matches.filter((match) => match.incident && match.incident !== null).length;

      const uniqueClubIds = new Set(teams.map((team) => team.clubId));
      const numOfClubs = uniqueClubIds.size;
      return {
        ongoingMatches,
        finishedMatches,
        matchesWithIncidents,
        pendingMatches,
        numOfCompetitions: seasons.length,
        numOfClubs,
      };
    }),
  );

  public matchesByScope$ = combineLatest([this.allMatches$, this.seasonsService.all$]).pipe(
    map(([matches, seasons]) => {
      const totalTeamsBySeason = seasons.reduce(
        (acc, season) => {
          acc[season.name] = season.teamIds.length;
          return acc;
        },
        {} as Record<string, number>,
      );

      const totalMatchesByScope = seasons.reduce(
        (acc, season) => {
          const scope = season.scope;
          if (!acc[scope]) {
            acc[scope] = 0;
          }
          acc[scope] += matches.filter((match) => match.seasonId === season.id).length;
          return acc;
        },
        {} as Record<string, number>,
      );

      return {
        totalTeamsBySeason,
        totalMatchesByScope,
      };
    }),
  );

  public sportsSummary$ = this.matches$.pipe(
    map((matches) => {
      const totalMatches = matches.length;

      const totalUsedBalls = matches.reduce((acc, match) => {
        const matchUsedBalls =
          match.games?.reduce((gameAcc, game) => {
            const usedBalls = Number(game.usedBalls);
            return usedBalls > 0 ? gameAcc + usedBalls : gameAcc;
          }, 0) || 0;
        return acc + matchUsedBalls;
      }, 0);

      const totalBrokenBalls = matches.reduce((acc, match) => {
        const matchBrokenBalls = match.games?.reduce((gameAcc, game) => gameAcc + Number(game.brokenBalls), 0) || 0;
        return acc + matchBrokenBalls;
      }, 0);

      const journeysResultsCount = matches.reduce(
        (acc, match) => {
          const result = match.result;
          if (result) {
            const resultKey = `${Number(result.local)}-${Number(result.visitor)}`;
            if (!acc[resultKey]) {
              acc[resultKey] = 0;
            }
            acc[resultKey]++;
          }
          return acc;
        },
        {} as Record<string, number>,
      );

      const gameResultsCount = matches.reduce(
        (acc, match) => {
          match.games?.forEach((game) => {
            let localWins = 0;
            let visitorWins = 0;
            game.sets.forEach((set) => {
              if (Number(set.local) > Number(set.visitor)) {
                localWins++;
              } else {
                visitorWins++;
              }
            });
            const gameResultKey = `${localWins}-${visitorWins}`;
            if (!acc[gameResultKey]) {
              acc[gameResultKey] = 0;
            }
            acc[gameResultKey]++;
          });
          return acc;
        },
        {} as Record<string, number>,
      );

      const usedBallsByRound = matches.reduce(
        (acc, match) => {
          const round = match.round;
          const matchUsedBalls =
            match.games?.reduce((gameAcc, game) => {
              const usedBalls = Number(game.usedBalls);
              return usedBalls > 0 ? gameAcc + usedBalls : gameAcc;
            }, 0) || 0;
          if (!acc[round]) {
            acc[round] = 0;
          }
          acc[round] += matchUsedBalls;
          return acc;
        },
        {} as Record<string, number>,
      );

      const brokenBallsByRound = matches.reduce(
        (acc, match) => {
          const round = match.round;
          const matchBrokenBalls = match.games?.reduce((gameAcc, game) => gameAcc + Number(game.brokenBalls), 0) || 0;
          if (!acc[round]) {
            acc[round] = 0;
          }
          acc[round] += matchBrokenBalls;
          return acc;
        },
        {} as Record<string, number>,
      );

      return {
        totalMatches,
        totalUsedBalls,
        totalBrokenBalls,
        journeysResultsCount,
        gameResultsCount,
        usedBallsByRound,
        brokenBallsByRound,
      };
    }),
  );

  public statsSummary$ = combineLatest([this.clubs$, this.teams$, this.users$]).pipe(
    map(([clubs, teams, users]) => {
      const clubsByProvince = clubs.reduce(
        (acc, club) => {
          const province = club.address?.province;
          if (province) {
            if (!acc[province]) {
              acc[province] = 0;
            }
            acc[province]++;
          }
          return acc;
        },
        {} as Record<string, number>,
      );

      const teamsByClub = teams.reduce(
        (acc, team) => {
          const club = clubs.find((c) => c.id === team.clubId);
          if (club) {
            if (!acc[club.name]) {
              acc[club.name] = 0;
            }
            acc[club.name]++;
          }
          return acc;
        },
        {} as Record<string, number>,
      );

      const playersByClub = users.reduce(
        (acc, user) => {
          const club = clubs.find((c) => c.id === user.clubId);
          if (club) {
            if (!acc[club.name]) {
              acc[club.name] = 0;
            }
            acc[club.name]++;
          }
          return acc;
        },
        {} as Record<string, number>,
      );

      const totalPlayers = users.length;
      const totalClubs = clubs.length;
      const averagePlayersPerClub = totalClubs > 0 ? totalPlayers / totalClubs : 0;

      const currentYear = new Date().getFullYear();
      const ageRanges = users.reduce(
        (acc, user) => {
          const birthYear = new Date(user.birthDate).getFullYear();
          const age = currentYear - birthYear;
          const rangeStart = Math.floor(age / 5) * 5;
          const rangeEnd = rangeStart + 4;
          const rangeKey = `${rangeStart}-${rangeEnd}`;
          if (!acc[rangeKey]) {
            acc[rangeKey] = 0;
          }
          acc[rangeKey]++;
          return acc;
        },
        {} as Record<string, number>,
      );

      const sortedAgeRanges = Object.keys(ageRanges)
        .sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]))
        .reduce(
          (acc, key) => {
            acc[key] = ageRanges[key];
            return acc;
          },
          {} as Record<string, number>,
        );

      const ageRangesByGender = users.reduce(
        (acc, user) => {
          const birthYear = new Date(user.birthDate).getFullYear();
          const age = currentYear - birthYear;
          const rangeStart = Math.floor(age / 5) * 5;
          const rangeEnd = rangeStart + 4;
          const rangeKey = `${rangeStart}-${rangeEnd}`;
          const gender = user.gender;

          if (!acc[rangeKey]) {
            acc[rangeKey] = { male: 0, female: 0, other: 0 };
          }

          if (gender === UserGender.MALE) {
            acc[rangeKey].male++;
          } else if (UserGender.FEMALE) {
            acc[rangeKey].female++;
          } else {
            acc[rangeKey].other++;
          }

          return acc;
        },
        {} as Record<string, { male: number; female: number; other: number }>,
      );

      const sortedAgeRangesByGender = Object.keys(ageRangesByGender)
        .sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]))
        .reduce(
          (acc, key) => {
            acc[key] = ageRangesByGender[key];
            return acc;
          },
          {} as Record<string, { male: number; female: number; other: number }>,
        );

      return {
        clubsByProvince,
        teamsByClub,
        playersByClub,
        averagePlayersPerClub,
        ageRanges: sortedAgeRanges,
        ageRangesByGender: sortedAgeRangesByGender,
      };
    }),
  );

  constructor(
    private firestore: FirestoreService,
    private seasonManager: SeasonManagerService,
    private seasonsService: SeasonsService,
  ) {}
}
